<template>
  <el-drawer
    title="點數卡紀錄詳情"
    :visible.sync="syncShow"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    class="classTicket-record-detail"
    @close="$emit('close')"
  >
    <div style="padding: 20px">
      <PointCardInfoBlock :data="selectPointCard"  />
      <PointCardUseBlock v-if="showEditBlock" :data="selectPointCard" @refresh="refresh" />
      <PointCardAddBlock v-if="showEditBlock" :data="selectPointCard" @refresh="refresh" />
      <PointCardUsageLogBlock :data="selectPointCard" />
    </div>
  </el-drawer>
</template>

<script>
import { defineComponent, computed } from 'vue'
import PointCardInfoBlock from './PointCardInfoBlock.vue'
import PointCardUseBlock from './PointCardUseBlock.vue'
import PointCardAddBlock from './PointCardAddBlock.vue'
import PointCardUsageLogBlock from './PointCardUsageLogBlock.vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'PointCardRecordDetail',
  components: {
    PointCardInfoBlock,
    PointCardUseBlock,
    PointCardAddBlock,
    PointCardUsageLogBlock,
  },
  props: {
    show: Boolean,
    selectPointCard: Object,
  },
  setup (props, { emit }) {
    const showEditBlock = computed(() => get(props.selectPointCard, 'status') === 'available')
    const refresh = () => {
      emit('refresh')
    }
    return { refresh, showEditBlock }
  },
  computed: {
    syncShow: {
      get () {
        return this.show
      },
      set (data) {
        this.$emit('opend:show', data)
      },
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
@apply mb-0;
}

.section-block-title {
@apply text-primary-100 font-medium text-[18px] pb-[12px];
}
::v-deep .el-drawer__header {
@apply mb-0;
}

</style>
