<template>
  <el-dialog
    title="綁定手機號碼"
    :visible="show"
    :close-on-click-modal="false"
    @closed="onCloseDialog"
    @close="$emit('update:show', false)"
  >
    <BaseElForm
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="left"
      label-width="120px"
    >
      <BaseElFormItem label="手機號碼" prop="phone">
        <BaseElInput v-model="formData.phone" placeholder="請輸入手機號碼" />
      </BaseElFormItem>
    </BaseElForm>
    <div slot="footer">
      <BaseElButton
        style="width: 140px; margin-right: 36px"
        plain
        @click="$emit('update:show', false)"
      >
        取消
      </BaseElButton>
      <BaseElButton
        style="width: 140px"
        type="primary"
        @click="updateAuthPhone"
      >
        儲存
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules, phoneRules } from '@/validation'
import formUtils from '@/utils/form'

export default {
  name: 'BindPhoneDialog',
  props: ['show'],
  data: () => ({
    formData: {
      phone: '',
    },
    formRules: {
      phone: [noEmptyRules(), phoneRules()],
    },
  }),
  methods: {
    async updateAuthPhone () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      this.$emit('bind', this.formData)
    },
    onCloseDialog () {
      this.formData.phone = ''
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style lang="postcss" scoped></style>
