<template>
  <div class="data-table">
    <BaseTable v-loading="loading.table" :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="訂閱狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="selectedStatus(get(scope.row, 'status'), 'tagType')">{{ selectedStatus(get(scope.row, 'status'), 'label') }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="扣款失敗紀錄" prop="failRecord" align="center">
        <template slot-scope="scope">
          <p :class="{'text-danger' : scope.row.failRecord}">{{ scope.row.failRecord || '-' }}</p>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="訂閱方案名稱" prop="planName" align="center" />
      <BaseElTableColumn label="品項組合名稱" prop="combineName" align="center" />
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <BaseElButton class="text-primary-100 underline" type="text" @click="onRowView(scope.row)">查看</BaseElButton>
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import { subscribeOrderStatusConfig } from '@/config/subscribe'
import { GetSubscribeOrder, GetSubscribeOrderCount } from '@/api/subscribe'

export default defineComponent({
  name: 'DataTable',
  components: {
    EmptyBlock,
  },
  props: ['member'],
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const {
      shopId,
      loading,
      search,
      extendData,
      tableData,
      tableDataCount,
      tableOptions,
      pageStartIndex,
    } = useTable()

    extendData('search', {
      code: null,
    })

    const displayData = computed(() => {
      const data = tableData.value
      return map(data, (i) => {
        const snapshot = get(i, 'snapshot')
        return {
          id: get(i, 'id'),
          name: get(i, 'memberName') || '-',
          phone: get(i, 'memberPhone') || '-',
          status: get(i, 'status'),
          failRecord: get(i, 'failRecord') ? `${get(i, 'failRecord')} 筆` : null,
          planName: get(snapshot, 'name') || '-',
          combineName: get(snapshot, 'planEquityPack.equityPack.name') || '-',
        }
      })
    })

    const getSubscribeOrder = async (payload) => {
      const [res, err] = await GetSubscribeOrder(payload)
      if (err) return window.$message.error(err)
      tableData.value = res
    }
    const getSubscribeOrderCount = async (payload) => {
      const [res, err] = await GetSubscribeOrderCount(payload)
      if (err) return window.$message.error(err)
      tableDataCount.value = res.count
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        sortBy: 'createdAt',
        sortType: 'DESC',
        memberName: get(props.member, 'UserInfo.name') || undefined,

      }
      await Promise.allSettled([
        getSubscribeOrder(payload),
        getSubscribeOrderCount(payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    const selectedStatus = (status, attr) => {
      return get(subscribeOrderStatusConfig[status], attr)
    }
    const onRowView = (row) => {
      router.push({ name: 'SubscribeRecordEdit', params: { id: row.id }, query: { member: route.params.id } })
    }

    onMounted(async () => {
      console.log('member', props.member)
      refresh()
    })

    return {
      get,
      search,
      loading,
      refresh,
      displayData,
      onRowView,
      tableOptions,
      tableDataCount,
      selectedStatus,
    }
  },
})
</script>
