
<template>
  <div class="calssTicket-tab">
    <PageTitle
      title=""
      btn="新增次卡"
      btn2="匯出"
      @btnClick="punchCardDialog = true"
      @btn2Click="dialog.export = true"
    />
    <FiltersContainer>
      <BaseElInput
        v-model="search.code"
        clearable
        placeholder="搜尋次卡編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
      <BaseElInput
        v-model="search.name"
        clearable
        placeholder="搜尋次卡編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
    </FiltersContainer>

    <BaseTable
      v-loading="loading.table"
      :data="displayData"
      empty-text="暫無數據"
      :row-style="{ cursor: 'pointer' }"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn
        prop="code"
        label="次卡編號"
        align="center"
        fixed="left"
      />
      <BaseElTableColumn
        prop="name"
        label="次卡名稱"
        align="center"
        fixed="left"
      >
        <template slot-scope="scope">
          <LinkItem :to="{name: 'PunchCardSetting', query: {name: scope.row.name }}">{{ scope.row.name }}</LinkItem>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="status" label="狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="selectStatus(scope.row.status, 'tagType')">
            {{ selectStatus(scope.row.status, 'label') }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="amount" label="餘額/總次數" align="center" />
      <BaseElTableColumn prop="price" label="價格" align="center" />
      <BaseElTableColumn
          prop="createdAt"
          label="建立時間"
          align="center"
        >
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>
      <BaseElTableColumn prop="exp" label="有效期限" align="center" />
      <BaseElTableColumn
          prop="origin"
          label="來源"
          align="center"
        >
          <template slot-scope="scope">
            {{ selectOrigin(scope.row.origin, 'label') }}
          </template>
        </BaseElTableColumn>
      <BaseElTableColumn label="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            editBtn="詳情"
            deleteBtn="作廢"
            :hideDelete="scope.row.status !== 'available'"
            @edit="findPunchCard(scope.row)"
            @delete="deleteDialog = true, selectRow = scope.row"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh('punchCard')"
    />
    <CreatePunchCardDialog
      v-if="punchCardDialog"
      :member="member"
      @close="punchCardDialog = false"
      @refresh="refresh"
    />
    <PunchCardRecordDetail
      :show="showDrawer"
      :selectPunchCard="selectRow"
      @close="closeDrawer"
      @cancel="deleteDialog = true"
      @refresh="refresh"
    />
    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="作廢後將無法復原，確定要作廢？"
      width="40%"
      btnString="作廢"
      @close="deleteDialog = false"
      @delete="voidPunchCard(), deleteDialog = false"
    />

    <ExportOptionsDialog
      v-if="dialog.export"
      @close="dialog.export = false"
      @export="prepareExport"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-computed-properties-in-data */
import EmptyBlock from '@/components/EmptyBlock.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import PunchCardRecordDetail from '@/views/PunchCard/components/PunchCardRecordDetail.vue'
import {
  GetPunchCardRecord,
  GetPunchCardRecordCount,
  FindPunchCardRecord,
  VoidPunchCardRecord,
} from '@/api/punchCard'
import LinkItem from '@/components/LinkItem.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import CreatePunchCardDialog from '@/components/Dialog/CreatePunchCardDialog.vue'
import { get, map } from 'lodash'
import { punchCardStatusConfig, punchCardOriginConfig } from '@/config/punchCard'
import { ExportMoreSheetExcel } from '@/utils/excel'
import { useShop } from '@/use/shop'
import { onMounted, onActivated, reactive, computed, ref, defineComponent } from 'vue'
import { useTable } from '@/use/table'

export default defineComponent({
  name: 'PunchCardTab',
  components: {
    DeleteDialog,
    EmptyBlock,
    ExportOptionsDialog,
    PunchCardRecordDetail,
    LinkItem,
    CreatePunchCardDialog,
  },
  props: ['member'],
  setup (props, { emit }) {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
      dateFormat,
    } = useTable()
    const { shopId } = useShop()
    const dialog = reactive({
      export: false,
    })
    const punchCardDialog = ref(false)
    const showDrawer = ref(false)
    const selectRow = ref(null)
    const deleteDialog = ref(false)
    const search = reactive({
      code: '',
      name: '',
    })
    const displayData = computed(() => {
      return map(tableData.value, (item) => {
        const status = get(item, 'status')
        const remainAmountName = selectStatus(status, 'amount')
        const remainAmount = item[remainAmountName]
        const amount = item.totalAmount === null ? '無上限' : `${remainAmount}/${item.totalAmount}`
        const exp = item.expiredAt === null ? '無期限' : dateFormat(item.expiredAt)
        return {
          ...item,
          amount,
          exp,
        }
      })
    })
    const selectStatus = (status, attr) => {
      return get(punchCardStatusConfig[status], attr)
    }
    const selectOrigin = (origin, attr) => {
      return get(punchCardOriginConfig[origin], attr)
    }
    const closeDrawer = () => {
      showDrawer.value = false
      selectRow.value = null
    }
    const voidPunchCard = async () => {
      const [res, err] = await VoidPunchCardRecord({
        shopId: shopId.value,
        punchCardRecordId: get(selectRow, 'value.id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('作廢成功')
      await refresh()
    }
    const findPunchCard = async (row) => {
      selectRow.value = row
      await findPunchCardRecord()
      showDrawer.value = true
    }
    const findPunchCardRecord = async () => {
      const [res, err] = await FindPunchCardRecord({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      selectRow.value = res
    }

    const prepareExport = async ({ all, range }) => {
      // TODO: 移除舊的匯出功能
      // dialog.export = false
      // const payload = {
      //   shopId: shopId.value,
      //   MemberId: props.member.id,
      //   createdAtStart: get(range, '[0]') ? dayjs(get(range, '[0]')).toDate() : undefined,
      //   createdAtEnd: get(range, '[0]') ? dayjs(get(range, '[1]')).toDate() : undefined,
      // }
      // exportState.modal = true
      // exportState.exportting = true
      // try {
      //   const res = await Promise.all([
      //     GetClassTicketRecordCount(payload),
      //     GetClassTicketUseRecordCount(payload),
      //     GetClassTicketRecordSerialCount(payload, { oldInstance: true }),
      //   ])
      //   exportState.dataCount = res
      //   if (!totalDataCount.value) {
      //     exportState.content = '尚無資料可匯出'
      //     exportState.error = true
      //     window.$message.warning('尚無資料可匯出')
      //     return
      //   }

      //   const [recordData, useRecordData, serialData] = await Promise.all([
      //     getExportDataOld({ stage: 0, fetchAPI: GetClassTicketRecord, payload }),
      //     getExportDataOld({ stage: 1, fetchAPI: GetClassTicketUseRecord, payload }),
      //     getExportDataOld({ stage: 2, fetchAPI: GetClassTicketRecordSerialOld, payload }),
      //   ])

      //   const exportData = {
      //     recordData: [],
      //     useRecordData: [],
      //     serialData: [],
      //   }

      //   for (const subitem of serialData) {
      //     const row = formatAddRecordSerialData(subitem)
      //     exportData.serialData.push(row)
      //   }
      //   for (const item of recordData) {
      //     exportData.recordData.push(formatAddRecordData(item))
      //   }
      //   for (const item of useRecordData) {
      //     exportData.useRecordData.push(formatUseRecordData(item))
      //   }
      //   const sheets = [
      //     {
      //       sheetName: '堂票新增紀錄',
      //       data: exportData.recordData,
      //     },
      //     {
      //       sheetName: '堂票序號新增紀錄',
      //       data: exportData.serialData,
      //     },
      //     {
      //       sheetName: '堂票使用紀錄',
      //       data: exportData.useRecordData,
      //     },
      //   ]
      //   exportState.success = true
      //   exportState.content = '匯出完成'
      //   ExportMoreSheetExcel(sheets, '堂票新增紀錄')
      // } catch (error) {
      //   exportState.exportting = false
      //   exportState.error = true
      // }
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        code: search.code || undefined,
        MemberId: props.member ? props.member.id : undefined,
        name: search.name || undefined,
      }
      await Promise.allSettled([
        fetchData(GetPunchCardRecord, payload),
        fetchDataCount(GetPunchCardRecordCount, payload),
      ])
    }
    const refresh = async (resetPage) => {
      if (resetPage) tableOptions.page = 1
      loading.table = true
      await getTableData()
      if (selectRow.value) await findPunchCardRecord()
      loading.table = false
    }
    onMounted( async() => {
      await refresh()
    })
    onActivated( async() => {
      await refresh()
    })
    return {
      ExportMoreSheetExcel,
      prepareExport,
      dialog,
      get,
      selectStatus,
      selectOrigin,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      search,
      refresh,
      displayData,
      dateFormat,
      findPunchCard,
      deleteDialog,
      showDrawer,
      voidPunchCard,
      selectRow,
      closeDrawer,
      punchCardDialog,
    }
  },
})
</script>

<style scoped lang="scss">
</style>
