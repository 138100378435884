<template>
  <el-dialog
    title="新增次卡"
    visible
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="100px"
    >
      <BaseElFormItem label="次卡" prop="punchCardId">
        <PunchCardSearch
          :model.sync="formData.punchCardId"
          objKey="id"
        />
      </BaseElFormItem>
      <BaseElFormItem label="組數" prop="multipleAmount">
        <BaseElInput
          v-model="formData.multipleAmount"
          placeholder="請輸入數量"
        />
      </BaseElFormItem>
      <BaseElFormItem label="單價" prop="price">
        <BaseElInput
          v-model="formData.price"
          type="number"
          placeholder="請輸入單價"
        />
      </BaseElFormItem>
      <BaseElFormItem label="備註" prop="note">
        <BaseElInput v-model="formData.note" placeholder="請輸入" />
      </BaseElFormItem>
    </BaseElForm>
    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">返回</BaseElButton>
      <BaseElButton type="primary" @click="onSubmit">確認</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { noEmptyRules } from '@/validation'
import PunchCardSearch from '@/components/Search/PunchCardSearch.vue'
import { useBaseForm } from '@/use/useForm'
import { CreatePunchCardRecord } from '@/api/punchCard'
import { useShop } from '@/use/shop'
import { get } from 'lodash'

export default defineComponent({
  name: 'CreatePunchCardDialog',
  components: {
    PunchCardSearch,
  },
  props: {
    pointCardForm: {
      type: Object,
      default: () => ({}),
    },
    member: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { formData, formRef, initFormData, checkForm } = useBaseForm()
    const { shopId } = useShop()
    const formRules = computed(() => {
      return {
        punchCardId: [noEmptyRules()],
        multipleAmount: [noEmptyRules()],
        price: [noEmptyRules()],
      }
    })
    initFormData({
      punchCardId: null,
      multipleAmount: '',
      price: '',
      note: '',
    })
    const onSubmit = async () => {
      const pass = await checkForm(formRef.value)
      if (!pass) return
      const [res, err] = await CreatePunchCardRecord({
        shopId: shopId.value,
        memberId: get(props.member, 'id'),
        punchCardId: get(formData, 'punchCardId'),
        price: get(formData, 'price'),
        multipleAmount: get(formData, 'multipleAmount'),
        note: get(formData, 'note') === '' ? undefined : get(formData, 'note'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('新增成功')
      emit('refresh')
      emit('close')
    }
    return {
      formData,
      formRef,
      formRules,
      initFormData,
      checkForm,
      onSubmit,
    }
  }
})
</script>

<style scoped lang="scss">
</style>
