<template>
  <el-dialog
    title="新增進場"
    width="560px"
    :visible.sync="syncShow"
    destroy-on-close
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="進場門市" prop="branchId">
        <BaseElSelect
          class="w-full"
          v-model="formData.branchId"
        >
          <BaseElSelectOption
            v-for="item in storeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
    <span slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
        <BaseElButton type="primary" @click="onSubmit"> 確認 </BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
// import EntryInfoBlock from './EntryInfoBlock.vue'
import { useShop } from '@/use/shop'
import { GetBranchStore } from '@/api/branchStore'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'BranchDialog',
  components: {
    // EntryInfoBlock,
  },
  props: {
    show: Boolean,
    selectEntryRecord: Object,
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, checkForm } = useBaseForm()
    const { shopId } = useShop()
    initFormData({
      branchId: null,
    })
    const formRules = computed(() => {
      return {
        branchId: [noEmptyRules()]
      }
    })
    const storeList = ref([])
    const getBranch = async () => {
      const [res, err] = await GetBranchStore({
        shopId: shopId.value,
      })
      if (err) window.$message.error(err)
      storeList.value = res
    }
    const onSubmit = async () => {
      const pass = await checkForm(formRef.value)
      if (!pass) return
      emit('confirm', formData.branchId)
    }
    onMounted(() => {
      getBranch()
    })
    return {
      storeList,
      formRef,
      formData,
      formRules,
      onSubmit,
    }
  },
  computed: {
    syncShow: {
      get () {
        return this.show
      },
      set (data) {
        this.$emit('opend:show', data)
      },
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
@apply mb-0;
}

.section-block-title {
@apply text-primary-100 font-medium text-[18px] pb-[12px];
}
::v-deep .el-drawer__header {
@apply mb-0;
}
::v-deep .el-input {
  @apply !max-w-none
}

</style>
