<template>
  <BaseDialog
    :show="true"
    width="510px"
    :title="`使用堂票紀錄 - ${record.name}` "
    @close="$emit('close')"
  >
    <BaseElForm
      ref="useTicketFormRef"
      :model="formData"
      :rules="useTicketFormRules"
      label-position="top"
      label-width="100px"
    >
      <BaseElFormItem
        label="使用堂票"
        prop="use"
      >
        <div class="flex items-center gap-[20px]">
          <BaseElInput
            v-model="formData.use"
            clearable
            style="width: 100%"
            placeholder="請輸入次數"
            :disabled="record.status !== 'open'"
          />
          <BaseElButton
            ref="useTicketBtn"
            type="primary"
            :disabled="record.status !== 'open'"
            @click="useClassTicket"
          >
            確認
          </BaseElButton>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <BaseElForm
      ref="cancelTicketFormRef"
      :model="formData"
      :rules="cancelTicketFormRules"
      label-position="top"
      label-width="100px"
    >
      <BaseElFormItem prop="cancel">
        <template slot="label">
          <FormItemTooltipLabel label="取消使用堂票(補發)" :tooltipWidth="200">
            說明：系統會依照取消堂票張數，補發同樣數量給該位會員。
          </FormItemTooltipLabel>
        </template>
        <div class="flex items-center gap-[20px]">
          <BaseElInput
            v-model="formData.cancel"
            clearable
            style="width: 100%"
            placeholder="請輸入次數"
            :disabled="record.status !== 'open'"
          />
          <BaseElButton
            ref="cancelTicketBtn"
            type="primary"
            :disabled="record.status !== 'open'"
            @click="cancelUseClassTicket"
          >
            確認
          </BaseElButton>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <div v-loading="loading.fetch">
      <UseClassTicketRecordStatistic :record="record" />
      <UseClassTicketRecordList :record="classTicketUseRecord" />
    </div>

    <template #btnContainer>
      <div />
    </template>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, ref, reactive, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import UseClassTicketRecordStatistic from './UseClassTicketRecordStatistic.vue'
import UseClassTicketRecordList from './UseClassTicketRecordList.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { CancelUseClassTicket, FindClassTicketRecord, UseClassTicket } from '@/api/classTicket'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation/index'
import dayjs from '@/lib/dayjs'

import formUtils from '@/utils/form'
import { useShop } from '@/use/shop'
export default defineComponent({
  name: 'EditMemberClassTicketRecordModal',
  components: { BaseDialog, UseClassTicketRecordList, UseClassTicketRecordStatistic, FormItemTooltipLabel },
  props: {
    record: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const useTicketFormRef = ref(null)
    const cancelTicketFormRef = ref(null)
    const classTicketUseRecord = ref([])

    const loading = reactive({
      use: false,
      cancel: false,
      fetch: true,
    })

    const formData = reactive({
      use: null,
      cancel: null,
    })

    const useTicketFormRules = computed(() => ({
      use: [noEmptyRules(), isDigitRules(), rangeRules(1, props.record.availableTimes)],
    }))
    const cancelTicketFormRules = computed(() => ({
      cancel: [noEmptyRules(), isDigitRules(), rangeRules(1, props.record.usedTimes)],
    }))

    const availableTimes = computed(() => {
      if (!props.record) return 0
      const available = props.record.availableTimes
      const used = props.record.usedTimes
      return available - used
    })

    const sortUseRecordList = () => {
      classTicketUseRecord.value.sort((a, b) => {
        if (dayjs(a.createdAt).unix() > dayjs(b.createdAt).unix()) return -1
        else if (dayjs(b.createdAt).unix() > dayjs(a.createdAt).unix()) return 1
      })
    }

    //= > 使用堂票
    const useClassTicket = async () => {
      if (loading.use) return
      if (availableTimes.value === 0) { return window.$message.error('目前已無剩餘次數') }
      if (!(await formUtils.checkForm(useTicketFormRef.value))) return
      loading.use = true
      try {
        await UseClassTicket({
          shopId: shopId.value,
          id: props.record.id,
          times: formData.use,
        })
        // await this.getClassTicketRecord()
        emit('refresh')

        await refresh()
        // this.updateSelectedClassTicketUsedTimes()

        window.$message.success('已使用堂票!')
        formData.use = null
        loading.use = false
        clearAllFormValidate()
      } catch (error) {
        console.log(error)
        window.$message.error('目前已無剩餘次數')
        loading.use = false
      }
    }

    //= > 取消使用堂票
    const cancelUseClassTicket = async () => {
      if (loading.cancel) return
      if (!(await formUtils.checkForm(cancelTicketFormRef.value))) return
      loading.cancel = true
      try {
        await CancelUseClassTicket({
          shopId: shopId.value,
          id: props.record.id,
          times: formData.cancel,
        })
        emit('refresh')
        await refresh()
        // this.updateSelectedClassTicketUsedTimes()

        window.$message.success('已取消使用堂票!')
        formData.cancel = null
        loading.cancel = false
        clearAllFormValidate()
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
        loading.cancel = false
      }
    }

    const refresh = async () => {
      loading.fetch = true
      const res = await FindClassTicketRecord({
        shopId: shopId.value,
        id: props.record.id,
      })
      classTicketUseRecord.value = res.ClassTicketRecordUses
      sortUseRecordList()
      loading.fetch = false
    }

    const clearAllFormValidate = () => {
      setTimeout(() => {
        formUtils.clearValidate(useTicketFormRef.value)
        formUtils.clearValidate(cancelTicketFormRef.value)
      }, 200)
    }

    onMounted(async () => {
      refresh()
    })

    return {
      formData,
      useTicketFormRef,
      cancelTicketFormRef,
      useTicketFormRules,
      cancelTicketFormRules,
      loading,
      useClassTicket,
      cancelUseClassTicket,
      classTicketUseRecord,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
