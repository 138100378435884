
<template>
  <div class="calssTicket-tab">
    <PageTitle
      title=""
      btn="新增進場"
      btn2="匯出"
      @btnClick="dialog.branch = true"
      @btn2Click="dialog.export = true"
    />
    <FiltersContainer>
      <BaseElSelect
        v-model="search.status"
        style="max-width: 400px"
        clearable
        placeholder="出場狀態"
        @change="refresh(true)"
      >
        <BaseElSelectOption
          v-for="item in entryFilterStatusConfig"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </BaseElSelect>
      <el-date-picker
        v-model="search.entryAt"
        editable
        type="datetimerange"
        format="yyyy-MM-dd HH:mm"
        range-separator="至"
        start-placeholder="進場時間開始"
        end-placeholder="進場時間結束"
        @change="refresh(true)"
      />
    </FiltersContainer>

    <BaseTable
      v-loading="loading.table"
      :data="displayData"
      empty-text="暫無數據"
      :row-style="{ cursor: 'pointer' }"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="entryEntitlementType" label="進場權益" align="center" />
      <BaseElTableColumn prop="entryAt" label="進場時間" align="center" />
      <BaseElTableColumn prop="exitAt" label="出場時間" align="center" />
      <BaseElTableColumn prop="status" label="出場狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="selectStatus(scope.row.status, 'tagType')">
            {{ selectStatus(scope.row.status, 'label') }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="exitNote" label="備註" align="center" />
      <BaseElTableColumn label="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            editBtn="詳情"
            hideDelete
            copyBtn="手動出場"
            :hideCopy="scope.row.status === 'exited'"
            @edit="showDetail(scope.row)"
            @copy="manualExit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh('pointCard')"
    />
    <EntryRecordDetail
      :show="showDrawer"
      :selectEntryRecord="selectRow"
      @close="closeDrawer"
      @manualExit="manualExit(selectRow)"
    />
    <ExportOptionsDialog
      v-if="dialog.export"
      @close="dialog.export = false"
      @export="prepareExport"
    />
    <ManualExitDialog
      :show="dialog.manualExit"
      :selectEntryRecord="selectRow"
      @close="dialog.manualExit = false;closeDrawer()"
      @refresh="refresh(false)"
    />
    <BranchDialog
      :show="dialog.branch"
      @close="dialog.branch = false"
      @confirm="toCreateEntryRecord"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-computed-properties-in-data */
import EmptyBlock from '@/components/EmptyBlock.vue'
import EntryRecordDetail from '@/views/EntryControl/components/EntryRecordDetail.vue'
import ManualExitDialog from '@/views/EntryControl/components/ManualExitDialog.vue'
import { GetEntryControlRecord, GetEntryControlRecordCount, FindEntryControlRecord } from '@/api/entryControl'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import BranchDialog from './components/BranchDialog.vue'
import { get, map } from 'lodash'
import { entryFilterStatusConfig, entitlementTypeConfig } from '@/config/entryControl'
import { useShop } from '@/use/shop'
import { onMounted, onActivated, reactive, computed, ref, defineComponent } from 'vue'
import { useTable } from '@/use/table'
import { useRouter, useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'EntryRecordTab',
  components: {
    EmptyBlock,
    ExportOptionsDialog,
    EntryRecordDetail,
    ManualExitDialog,
    BranchDialog,
  },
  props: ['member'],
  setup (props, { emit }) {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
      dateFormat,
    } = useTable()
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const dialog = reactive({
      export: false,
      manualExit: false,
      branch: false,
    })
    const pointCardDialog = ref(false)
    const showDrawer = ref(false)
    const selectRow = ref(null)
    const search = reactive({
      status: '',
      entryAt: null,
    })

    const displayData = computed(() => {
      return map(tableData.value, (item) => {
        const entryAt = get(item, 'entryAt') ? dateFormat(get(item, 'entryAt')) : '-'
        const exitAt = get(item, 'exitAt') ? dateFormat(get(item, 'exitAt')) : '-'
        const name = get(item, 'Member.UserInfo.name')
        const entryEntitlementType = selectType(get(item, 'entryEntitlementType'), 'label')
        const exitNote = get(item, 'exitNote') || '-'
        return {
          ...item,
          entryAt,
          exitAt,
          name,
          entryEntitlementType,
          exitNote,
        }
      })
    })
    const selectStatus = (status, attr) => {
      return get(entryFilterStatusConfig[status], attr)
    }
    const selectType = (status, attr) => {
      return get(entitlementTypeConfig[status], attr)
    }
    const closeDrawer = () => {
      showDrawer.value = false
      selectRow.value = null
    }
    const findEntryControlRecord = async (row) => {
      selectRow.value = row
      const [res, err] = await FindEntryControlRecord({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      selectRow.value = res
    }
    const showDetail = async (row) => {
      selectRow.value = row
      await findEntryControlRecord(row)
      showDrawer.value = true
    }
    const manualExit = async (row) => {
      selectRow.value = row
      await findEntryControlRecord(row)
      dialog.manualExit = true
    }
    const toCreateEntryRecord = (branchId) => {
      router.push({ name: 'CreateEntryRecord', params: { id: branchId }, query: { memberId: get(props.member, 'id') } })
    }
    const prepareExport = async ({ all, range }) => {
      // TODO: 移除舊的匯出功能
      // dialog.export = false
      // const payload = {
      //   shopId: shopId.value,
      //   MemberId: props.member.id,
      //   createdAtStart: get(range, '[0]') ? dayjs(get(range, '[0]')).toDate() : undefined,
      //   createdAtEnd: get(range, '[0]') ? dayjs(get(range, '[1]')).toDate() : undefined,
      // }
      // exportState.modal = true
      // exportState.exportting = true
      // try {
      //   const res = await Promise.all([
      //     GetClassTicketRecordCount(payload),
      //     GetClassTicketUseRecordCount(payload),
      //     GetClassTicketRecordSerialCount(payload, { oldInstance: true }),
      //   ])
      //   exportState.dataCount = res
      //   if (!totalDataCount.value) {
      //     exportState.content = '尚無資料可匯出'
      //     exportState.error = true
      //     window.$message.warning('尚無資料可匯出')
      //     return
      //   }

      //   const [recordData, useRecordData, serialData] = await Promise.all([
      //     getExportDataOld({ stage: 0, fetchAPI: GetClassTicketRecord, payload }),
      //     getExportDataOld({ stage: 1, fetchAPI: GetClassTicketUseRecord, payload }),
      //     getExportDataOld({ stage: 2, fetchAPI: GetClassTicketRecordSerialOld, payload }),
      //   ])

      //   const exportData = {
      //     recordData: [],
      //     useRecordData: [],
      //     serialData: [],
      //   }

      //   for (const subitem of serialData) {
      //     const row = formatAddRecordSerialData(subitem)
      //     exportData.serialData.push(row)
      //   }
      //   for (const item of recordData) {
      //     exportData.recordData.push(formatAddRecordData(item))
      //   }
      //   for (const item of useRecordData) {
      //     exportData.useRecordData.push(formatUseRecordData(item))
      //   }
      //   const sheets = [
      //     {
      //       sheetName: '堂票新增紀錄',
      //       data: exportData.recordData,
      //     },
      //     {
      //       sheetName: '堂票序號新增紀錄',
      //       data: exportData.serialData,
      //     },
      //     {
      //       sheetName: '堂票使用紀錄',
      //       data: exportData.useRecordData,
      //     },
      //   ]
      //   exportState.success = true
      //   exportState.content = '匯出完成'
      //   ExportMoreSheetExcel(sheets, '堂票新增紀錄')
      // } catch (error) {
      //   exportState.exportting = false
      //   exportState.error = true
      // }
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        MemberId: props.member ? props.member.id : undefined,
        status: search.status || undefined,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        entryAtStart: get(search, 'entryAt[0]') || undefined,
        entryAtEnd: get(search, 'entryAt[1]') || undefined,
      }
      await Promise.allSettled([
        fetchData(GetEntryControlRecord, payload),
        fetchDataCount(GetEntryControlRecordCount, payload),
      ])
    }
    const refresh = async (resetPage) => {
      if (resetPage) tableOptions.page = 1
      loading.table = true
      await getTableData()
      if (selectRow.value) await findEntryControlRecord()
      loading.table = false
    }
    onMounted( async() => {
      await refresh()
    })
    onActivated( async() => {
      await refresh()
    })
    return {
      prepareExport,
      dialog,
      get,
      selectStatus,
      selectType,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      search,
      refresh,
      displayData,
      showDrawer,
      selectRow,
      closeDrawer,
      pointCardDialog,
      entryFilterStatusConfig,
      showDetail,
      manualExit,
      toCreateEntryRecord,
    }
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-input {
  @apply w-full max-w-[400px];
}
</style>
