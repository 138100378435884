
<template>
  <div class="calssTicket-tab">
    <PageTitle
      title=""
      btn="新增"
      @btnClick="modal.create = true"
    />
    <FiltersContainer flat>
      <BaseElInput
        v-model="search.code"
        clearable
        placeholder="搜尋會員權益編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
      <BaseElInput
        v-model="search.name"
        clearable
        placeholder="搜尋會員權益名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
      <BaseElSelect v-model="search.status" placeholder="選擇狀態" clearable @change="refresh(true)" @clear="refresh(true)">
        <BaseElSelectOption
          v-for="item in recordStatusConfig"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </BaseElSelect>
    </FiltersContainer>

    <BaseTable
      v-loading="loading.table"
      :data="displayData"
      empty-text="暫無數據"
      :row-style="{ cursor: 'pointer' }"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn
        prop="code"
        label="會員權益編號"
        align="center"
        fixed="left"
      />
      <BaseElTableColumn
        prop="name"
        label="會員權益名稱"
        align="center"
        fixed="left"
      />
      <BaseElTableColumn prop="status" label="狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="selectStatus(scope.row.status, 'tagType')">
            {{ selectStatus(scope.row.status, 'label') }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="origin" label="來源" align="center" />
      <BaseElTableColumn prop="createdAt" label="建立時間" align="center" />
      <BaseElTableColumn label="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            :testNmae="scope.row.code"
            copyBtn="詳情"
            :editBtn="scope.row.status === 'active' ? '暫停' : '恢復'"
            deleteBtn="終止"
            :hideCopy="false"
            :hideEdit="scope.row.status === 'ended' || scope.row.status === 'terminated'"
            :hideDelete="scope.row.status !== 'active'"
            @copy="onRowDetail(scope.row)"
            @edit="scope.row.status === 'active' ? onRowPause(scope.row) : onRowResume(scope.row) "
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh"
    />
    <EndPeriodBenefitModal
      v-if="modal.end"
      :selectRow="selectRow"
      @close="modal.end = false"
      @refresh="refresh"
    />
    <PausePeriodBenefitModal
      v-if="modal.pause"
      :selectRow="selectRow"
      @close="modal.pause = false"
      @refresh="refresh"
    />
    <RetrievePeriodBenefitModal
      v-if="modal.retrieve"
      :selectRow="selectRow"
      @close="modal.retrieve = false"
      @refresh="refresh"
    />
    <PeriodicBenefitRecordDetailDrawer
      v-if="modal.detail"
      :selectRow="selectRow"
      :selectRowTimeline="selectRowTimeline"
      @close="modal.detail = false"
      @onPause="onRowPause"
      @onResume="onRowResume"
      @onDelete="onRowDelete"
    />
    <CreatePeriodicBenefitRecordModal
      v-if="modal.create"
      :periodicBenefitTemplate="periodicBenefitTemplate"
      :memberId="member.id"
      @close="modal.create = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-computed-properties-in-data */
import EmptyBlock from '@/components/EmptyBlock.vue'
import { get, map } from 'lodash'
import { useShop } from '@/use/shop'
import { onMounted, onActivated, reactive, computed, ref, defineComponent } from 'vue'
import { useTable } from '@/use/table'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import { recordOriginConfig, recordStatusConfig } from '@/config/periodicBenefit'
import { GetPeriodicBenefitRecord, GetPeriodicBenefitRecordCount, GetPeriodicBenefitTemplate, FindPeriodicBenefitRecord, GetPeriodicBenefitRecordTimeline } from '@/api/periodicBenefit'
import PausePeriodBenefitModal from '@/views/PeriodicBenefitRecord/components/PausePeriodBenefitModal.vue'
import EndPeriodBenefitModal from '@/views/PeriodicBenefitRecord/components/EndPeriodBenefitModal.vue'
import RetrievePeriodBenefitModal from '@/views/PeriodicBenefitRecord/components/RetrievePeriodBenefitModal.vue'
import PeriodicBenefitRecordDetailDrawer from '@/views/PeriodicBenefitRecord/components/PeriodicBenefitRecordDetailDrawer.vue'
import CreatePeriodicBenefitRecordModal from '@/views/PeriodicBenefitRecord/components/CreatePeriodicBenefitRecordModal.vue'

export default defineComponent({
  name: 'PunchCardTab',
  components: {
    EmptyBlock,
    FiltersContainer,
    PausePeriodBenefitModal,
    EndPeriodBenefitModal,
    RetrievePeriodBenefitModal,
    PeriodicBenefitRecordDetailDrawer,
    CreatePeriodicBenefitRecordModal,
  },
  props: ['member'],
  setup (props, { emit }) {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
      dateFormat,
    } = useTable()
    const { shopId } = useShop()
    const periodicBenefitTemplate = ref(null)
    const selectRow = ref(null)
    const selectRowTimeline = ref(null)
    const modal = reactive({
      end: false,
      pause: false,
      retrieve: false,
      detail: false,
      create: false,
    })
    const search = reactive({
      code: '',
      name: '',
      status: null,
    })
    const displayData = computed(() => {
      const data = tableData.value
      return map(data, item => {
        const { id, Member, MemberId, code, name, status, origin, createdAt, nextAwardTime } = item
        return {
          id,
          Member,
          MemberId,
          code,
          name,
          status,
          origin: selectOrigin(origin, 'label'),
          createdAt: dateFormat(createdAt),
          nextAwardTime,
        }
      })
    })
    const selectStatus = (status, attr) => {
      return get(recordStatusConfig[status], attr)
    }
    const selectOrigin = (origin, attr) => {
      return get(recordOriginConfig[origin], attr)
    }
    const findPeriodicBenefitRecord = async (row) => {
      const [res, err] = await FindPeriodicBenefitRecord({
        shopId: shopId.value,
        id: get(row, 'id'),
      })
      if (err) return window.$message.error(err)
      return res
    }
    const findperiodicBenefitRecordTimeline = async () => {
      const [res, err] = await GetPeriodicBenefitRecordTimeline({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      return res
    }
    const onRowDetail = async (row) => {
      selectRow.value = await findPeriodicBenefitRecord(row)
      selectRowTimeline.value = await findperiodicBenefitRecordTimeline(row)
      modal.detail = true
    }
    const onRowPause = (row) => {
      selectRow.value = row
      modal.pause = true
    }
    const onRowResume = (row) => {
      selectRow.value = row
      modal.retrieve = true
    }
    const onRowDelete = (row) => {
      selectRow.value = row
      modal.end = true
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        status: search.status || undefined,
        code: search.code || undefined,
        MemberId: props.member ? props.member.id : undefined,
        name: search.name || undefined,
      }
      await Promise.allSettled([
        fetchData(GetPeriodicBenefitRecord, payload),
        fetchDataCount(GetPeriodicBenefitRecordCount, payload),
        getPeriodicBenefitTemplate()
      ])
    }
    const getPeriodicBenefitTemplate = async () => {
      const [res, err] = await GetPeriodicBenefitTemplate({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      periodicBenefitTemplate.value = res
    }
    const refresh = async (resetPage) => {
      if (resetPage) tableOptions.page = 1
      loading.table = true
      await getTableData()
      await refreshDrawer()
      loading.table = false
    }
    const refreshDrawer = async () => {
      if (selectRow.value) selectRow.value = await findPeriodicBenefitRecord(selectRow.value)
    }
    onMounted( async() => {
      await refresh()
    })
    onActivated( async() => {
      await refresh()
    })
    return {
      get,
      selectStatus,
      selectOrigin,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      search,
      refresh,
      displayData,
      selectRow,
      selectRowTimeline,
      recordStatusConfig,
      onRowDetail,
      onRowPause,
      onRowResume,
      onRowDelete,
      modal,
      periodicBenefitTemplate,
    }
  },
})
</script>

<style scoped lang="scss">
</style>
