<template>
  <BaseElSelect
    v-model="syncModel"
    filterable
    remote
    :multiple="multiple"
    clearable
    reserve-keyword
    placeholder="請選擇使用券"
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    @change="$emit('change')"
    @clear="getDataList"
  >
    <BaseElSelectOption
      v-for="(item, index) in dataList"
      :key="`${index} - ${item.name}`"
      :label="item.name"
      :value="getValue(item)"
    />
  </BaseElSelect>
</template>
<script>
import { GetPospalCoupon } from '@/api/pospal'
import { defineComponent, onMounted, ref } from 'vue'
import { useShop } from '@/use/shop'
import { useVModel } from '@vueuse/core'
export default defineComponent({
  name: 'PospalCouponSearch',
  props: {
    value: [String, Number, Array],
    type: String,
    objKey: String,
    multiple: Boolean,
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const dataList = ref([])
    const loading = ref(false)
    const syncModel = useVModel(props, 'value', emit)

    const getDataList = async (query) => {
      const [res, err] = await GetPospalCoupon({
        shopId: shopId.value,
        name: query || undefined,
        limit: 100,
      })
      if (err) return console.log(err)
      dataList.value = res
    }

    const remoteSearch = async (query) => {
      query = query.trim()
      if (query === '') dataList.value = []

      try {
        loading.value = true
        setTimeout(async () => {
          await getDataList(query)
          loading.value = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    }

    const getValue = (item) => {
      if (props.objKey) return item[props.objKey]
      else return item
    }

    onMounted(async () => {
      await getDataList()
    })

    return { getValue, syncModel, dataList, getDataList, remoteSearch, loading }
  },
})
</script>

<style scoped lang="scss"></style>
