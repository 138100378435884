<template>
  <el-drawer
    title="會員等級詳細資訊"
    :visible="true"
    direction="rtl"
    class="member-level-detail"
    @close="$emit('close')"
  >
    <div class="content-wrapper">
      <BaseElForm label-position="left" label-width="150px">
        <BaseElFormItem label="當前等級" :show-message="false">
          {{ displayData.level }}
        </BaseElFormItem>
        <BaseElFormItem label="期限" :show-message="false">
          {{ displayData.expiredDate }}
        </BaseElFormItem>
        <!-- <BaseElFormItem label="等級優惠">
          cascsac
        </BaseElFormItem> -->
      </BaseElForm>

      <el-divider />

      <div v-loading="loading">
        <p style="margin-bottom: 24px">紀錄</p>

        <el-steps v-if="logs.length" direction="vertical" :active="logs.length" :space="81">
          <el-step v-for="log in logs" :key="log.id">
            <template #description>
              <MemberLevelRecordItem :record="log" />
            </template>
            <template #icon>
              <div class="w-full h-full bg-primary-100 rounded-full w-[15px] h-[15px]" />
            </template>
          </el-step>
        </el-steps>

        <EmptyBlock v-else />
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { FindMemberLevelRecord, FindMemberCurrentLevelRecord } from '@/api/memberLevel'
import MemberLevelRecordItem from './components/MemberLevelRecordItem.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'MemberLevelDetail',
  components: { MemberLevelRecordItem, EmptyBlock },
  props: {
    memberId: String,
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { simpleFetch, fetchAll } = useFetch()
    const currentLevelData = ref({})
    const logs = ref([])
    const loading = ref(false)
    const displayData = computed(() => {
      const data = currentLevelData.value

      let expiredDate
      const expiredAt = get(data, 'expiredAt')
      if (!expiredAt) expiredDate = '永久'
      else expiredDate = formatDate(expiredAt)

      return {
        level: get(data, 'MemberLevelConfig.name'),
        expiredDate,
      }
    })
    const displayLog = computed(() => {
      return []
    })

    onMounted(async () => {
      simpleFetch(FindMemberCurrentLevelRecord, { shopId: shopId.value, id: props.memberId, limit: 20 }, (res) => {
        currentLevelData.value = res
      })
      loading.value = true
      await fetchAll(FindMemberLevelRecord, { shopId: shopId.value, id: props.memberId }, (res) => {
        logs.value = res
      })
      loading.value = false
    })

    return { displayData, displayLog, logs, loading }
  },
})
</script>

<style lang="postcss" scoped>
.content-wrapper {
    @apply flex flex-col h-full;
    @apply px-[24px];
}

::v-deep .el-form-item {
    @apply mb-0;
}

::v-deep .el-step__description {
  @apply pr-0 text-gray-100 top-[-4px] relative;
}

::v-deep .el-step__icon.is-text {
  @apply top-[-4px] h-[15px] w-[15px] ml-[5px] bg-primary-100;
}

::v-deep .el-step__icon-inner {
  @apply hidden;
}
</style>
