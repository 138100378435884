<template>
  <div class="use-calssticket-record-list">
    <p class="text-normal text-gray-60 py-[4px]">編輯紀錄</p>

    <div v-if="displayData.length" class="flex flex-col gap-[4px] text-gray-60">
      <div v-for="item in displayData" :key="item.id" class="use-record-item" :class="gridCols">
        <p v-if="item.use">
          <template v-if="item.type === 'VOID_CANCEL'">作廢張數：{{ item.use }}</template>
          <template v-else-if="item.type === 'SYSTEM_OVERDUE'">過期張數：{{ item.use }}</template>
          <template v-else>使用張數：{{ item.use }}</template>
        </p>
        <p v-if="item.cancel">取消使用張數：{{ item.cancel }}</p>
        <p v-if="useBranch" class="text-center">{{ item.branchName }}</p>
        <p class="text-right">{{ item.createdAt }}</p>
      </div>
    </div>

    <p v-else class="text-center text-gray-60">暫無編輯紀錄</p>

    <div v-if="record.length > 3" class="flex justify-end">
      <BaseElButton type="text" class="see-all-btn" @click="allRecord = !allRecord">{{ allRecord ? '收合紀錄' : '檢視全部紀錄' }}</BaseElButton>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { formatDate } from '@/utils/date'
import { get, map, slice } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'UseClassTicketRecordList',
  props: {
    record: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const useBranch = computed(() => checkAction('admin.branch.page'))
    const allRecord = ref(false)

    const displayData = computed(() => {
      const data = map(props.record, (item) => {
        const createdAt = get(item, 'createdAt')
        const times = get(item, 'times')
        return {
          use: times > 0 ? times : null,
          cancel: times > 0 ? null : Math.abs(times),
          branchName: get(item, 'Branch.name') || '-',
          createdAt: createdAt ? formatDate(createdAt) : '-',
          type: get(item, 'type'),
        }
      })

      if (!allRecord.value) return slice(data, 0, 3)
      return data
    })
    const gridCols = computed(() => useBranch.value ? 'grid-cols-3' : 'grid-cols-2')

    return { displayData, allRecord, useBranch, gridCols }
  },
})

</script>

<style scoped lang="postcss">
.use-calssticket-record-list {
  @apply pt-[8px];
}
.use-record-item {
  @apply grid text-[16px];
}

.see-all-btn {
  @apply text-normal leading-[28px] p-0 text-right;
}
</style>
