<template>
  <el-dialog
    title="提醒"
    :visible="show"
    width="400px"
    :close-on-click-modal="false"
    @close="$emit('update:show', false)"
  >
    <div>
      解除綁定後，此會員需重新登入進行手機號碼驗證，以連結至改綁定的會員資料。
    </div>
    <div class="btn-container">
      <BaseElButton
        style="width: 140px"
        plain
        @click="$emit('update:show', false)"
      >
        取消
      </BaseElButton>
      <BaseElButton
        style="width: 140px"
        type="primary"
        @click="$emit('debind')"
      >
        確定
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'DeBindPhoneWarningDialog',
  props: ['show'],
  computed: {
    shop () {
      return this.$store.getters.shop
    },
  },
  methods: {
    async deleteAuthPhone () {
      this.$emit('debind')
    },
  },
}
</script>

<style lang="postcss" scoped>
.btn-container {
  @apply mt-[40px] flex justify-between;
}
</style>
