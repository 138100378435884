<template>
  <div class="classTicket-info mb-[32px]">
    <BaseElForm v-if="data" label-width="150px" label-position="left">
      <BaseElFormItem label="點數卡編號">
        <div>{{ data.code }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="點數卡名稱">
        <div>{{ data.name }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="持有會員">
        <div v-if="inMemberProfile">{{ get(data,'Member.UserInfo.name') }}</div>
        <div v-else class="underline cursor-pointer text-primary-100" @click="goToProfile(data)">{{ get(data,'Member.UserInfo.name') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="點數卡狀態">
        <Tag :type="selectStatus(data.status, 'tagType')" style="display: inline-flex;">
          {{ selectStatus(data.status, 'label') }}
        </Tag>
      </BaseElFormItem>
      <BaseElFormItem label="餘額/總次數">
        <div>{{ amount }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="價格">
        <div>{{ data.price }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="建立日期">
        {{ dateFormat(data.createdAt) }}
      </BaseElFormItem>
      <BaseElFormItem label="有效期限">
        {{ dateFormat(data.expireAt) }}
      </BaseElFormItem>
      <BaseElFormItem label="來源">
        {{ selectOrigin(data.origin, 'label') }}
      </BaseElFormItem>
      <BaseElFormItem label="備註">
        {{ data.originNote || '-' }}
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import { useTable } from '@/use/table'
import { pointCardStatusConfig, pointCardOriginConfig } from '@/config/pointCard'
import { useRouter, useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'PointCardInfoBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { dateFormat } = useTable()
    const router = useRouter()
    const route = useRoute()
    const amount = computed(() => {
      const data = props.data
      const status = get(data, 'status')
      const remainAmountName = selectStatus(status, 'amount')
      const remainAmount = data[remainAmountName]
      const amount = data.totalAmount === null ? '無上限' : `${remainAmount}/${data.totalAmount}`
      return amount
    })
    const inMemberProfile = computed(() => route.name === 'MemberProfile')
    const selectStatus = (status, attr) => {
      return get(pointCardStatusConfig[status], attr)
    }
    const selectOrigin = (origin, attr) => {
      return get(pointCardOriginConfig[origin], attr)
    }
    const goToProfile = (row) => {
      router.push(`/members/member-profile/${row.MemberId}/pointCard`)
    }
    return {
      dateFormat,
      selectStatus,
      selectOrigin,
      get,
      amount,
      goToProfile,
      inMemberProfile,
    }
  },
})
</script>

<style scoped lang="scss"></style>
