<template>
  <div class="data-table">
    <FilterContainer>
      <BaseElInput
        v-model="search.code"
        placeholder="輸入訂單編號"
        clearable
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
    </FilterContainer>
    <BaseTable v-loading="loading.table" :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="訂單編號" prop="code" align="center" />
      <BaseElTableColumn label="訂單日期" prop="createdAt" align="center" />
      <BaseElTableColumn label="付款狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'status.tagType')">
            {{ get(scope.row, 'status.label') }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="訂單總額" prop="orderPrice" align="center" />
      <BaseElTableColumn label="付款狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'paymentStatus.tagType')">
            {{ get(scope.row, 'paymentStatus.label') }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="付款方式" align="center">
        <template slot-scope="scope">
          {{ get(scope.row, 'paymentType.label') }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            editBtn="檢視"
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive, onMounted } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import FilterContainer from '@/components/Container/FiltersContainer.vue'
import { GetMemberShopOrder, GetMemberShopOrderCount } from '@/api/memberShop'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { formatDate } from '@/utils/date'
import {
  orderStatusConfig,
  orderPaymentStatusConfig,
  orderPaymentTypeConfig,
} from '@/config/memberShop'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'DataTable',
  components: { EmptyBlock, FilterContainer },
  props: ['memberId'],
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const {
      shopId,
      loading,
      search,
      extendData,
      tableData,
      tableDataCount,
      tableOptions,
      pageStartIndex,
      selectRow,
    } = useTable()

    extendData('search', {
      code: null,
    })

    const displayData = computed(() => {
      const data = tableData.value
      // handle data here
      // return data
      return map(data, (i) => {
        return {
          id: i.id,
          code: i.code,
          createdAt: formatDate(i.createdAt),
          orderPrice: i.paidAmount,
          status: get(orderStatusConfig, i.status),
          paymentStatus: get(
            orderPaymentStatusConfig,
            get(i, 'MemberStoreOrderPayment.status'),
          ),
          paymentType: get(
            orderPaymentTypeConfig,
            get(i, 'MemberStoreOrderPayment.paymentType'),
          ),
        }
      })
    })

    const getData = async (payload) => {
      const [res, err] = await GetMemberShopOrder(payload)
      if (err) return window.$message.error(err)
      tableData.value = res
    }
    const getDataCount = async (payload) => {
      const [res, err] = await GetMemberShopOrderCount(payload)
      if (err) return window.$message.error(err)
      tableDataCount.value = res.count
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        code: search.code || undefined,
        MemberId: props.memberId,
      }
      await Promise.allSettled([getData(payload), getDataCount(payload)])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    const onRowEdit = (row) => {
      router.push({
        name: 'MemberShopOrderDetail',
        params: {
          id: row.id,
        },
      })
    }

    onMounted(async () => {
      console.log('memberId', props.memberId)
      refresh()
    })

    return {
      get,
      search,
      loading,
      refresh,
      displayData,
      onRowEdit,
      tableOptions,
      tableDataCount,
    }
  },
})
</script>
