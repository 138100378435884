var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"profile-tab"},[_c('PageTitle',{attrs:{"title":""},on:{"btnClick":function($event){_vm.showEditDialog = true}}}),_c('div',{staticClass:"section-block"},[_c('BaseElForm',{ref:"form",staticClass:"form",attrs:{"model":_vm.formData,"label-position":"left","label-width":"180px"}},[_c('BaseElFormItem',{attrs:{"label":"用戶狀態","prop":"isBlock"}},[_c('BaseElSwitch',{attrs:{"active-text":"黑名單會員","inactive-text":"正常會員"},on:{"change":_vm.onChangeBlock},model:{value:(_vm.formData.isBlock),callback:function ($$v) {_vm.$set(_vm.formData, "isBlock", $$v)},expression:"formData.isBlock"}})],1),_c('BaseElFormItem',{attrs:{"label":"Line 綁定","prop":"memberAuthLine"}},[_c('div',{staticClass:"icon__vertical-align"},[(_vm.member.MemberAuthLine)?_c('img',{attrs:{"src":require("@/assets/member/icon-line-auth-yes.svg"),"alt":"Has Line auth"}}):_c('img',{attrs:{"src":require("@/assets/member/icon-line-auth-no.svg"),"alt":"Without Line auth"}})])]),(_vm.useMemberOpentix)?_c('BaseElFormItem',{attrs:{"label":"OPENTIX 綁定"}},[_c('div',{staticClass:"icon__vertical-align"},[(_vm.opentixBinded)?_c('img',{attrs:{"src":require("@/assets/member/icon-line-auth-yes.svg"),"alt":"Has Line auth"}}):_c('img',{attrs:{"src":require("@/assets/member/icon-line-auth-no.svg"),"alt":"Without Line auth"}})])]):_vm._e(),_c('BaseElFormItem',{attrs:{"label":"登入方式","prop":"memberUserAuth"}},[_c('div',{staticClass:"icon__vertical-align"},[(
              !_vm.member.User.AuthLine &&
                !_vm.member.User.AuthFB &&
                !_vm.member.User.AuthPhoneAccount
            )?_c('span',[_vm._v("尚未登入")]):_vm._e(),(_vm.member.User.AuthLine)?_c('img',{attrs:{"src":require("@/assets/member/icon-auth-line.svg"),"alt":"Line auth icon"}}):_vm._e(),(_vm.member.User.AuthFB)?_c('img',{attrs:{"src":require("@/assets/member/icon-auth-facebook.svg"),"alt":"Facebook auth icon"}}):_vm._e(),(_vm.member.User.AuthPhoneAccount)?_c('img',{attrs:{"src":require("@/assets/member/icon-auth-phone.svg"),"alt":"Phone Account auth icon"}}):_vm._e()])]),_c('BaseElFormItem',{attrs:{"label":"會員級別","prop":"level"}},[_c('div',{staticClass:"flex items-center",staticStyle:{"gap":"12px"}},[(_vm.formData.level > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.getDisplayLevel())+" ")]):_c('p',[_vm._v("無等級")]),_c('BaseElButton',{staticClass:"underline text-primary-100",attrs:{"type":"text"},on:{"click":function($event){_vm.showMemberLevelDetail = true}}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("查看")])])],1)]),(_vm.memberPolicyData.configData.enable)?_c('BaseElFormItem',{attrs:{"label":"權益政策"}},[_vm._v(" "+_vm._s(_vm.displayData.memberPolicyStatus)+" ")]):_vm._e(),_c('BaseElFormItem',{attrs:{"label":"手機號碼","prop":"phone"}},[_c('div',[_vm._v(" "+_vm._s(_vm.formData.phone || '尚未綁定')+" ")])]),_vm._l((_vm.baseUserSetting),function(field){return _c('BaseElFormItem',{key:field.key,attrs:{"label":field.name,"prop":field.key}},[_c('span',[_vm._v(" "+_vm._s(_vm.getBaseFormData(field) || '尚未填寫')+" ")])])}),_vm._l((_vm.customUserSetting),function(field){return _c('BaseElFormItem',{directives:[{name:"show",rawName:"v-show",value:(
          field.type !== 'companyDirectoryFirmTaxId' ||
            (_vm.showCompany && field.type === 'companyDirectoryFirmTaxId')
        ),expression:"\n          field.type !== 'companyDirectoryFirmTaxId' ||\n            (showCompany && field.type === 'companyDirectoryFirmTaxId')\n        "}],key:field.key,attrs:{"label":field.name,"prop":field.key}},[(!['restrictedImage'].includes(field.type))?_c('span',[_vm._v(" "+_vm._s(_vm.getCustomFormData(field) || '尚未填寫')+" ")]):_vm._e(),(field.type === 'restrictedImage')?_c('div',{staticClass:"flex items-center",staticStyle:{"gap":"12px"}},[_c('p',[_vm._v(_vm._s(_vm.getCustomFormData(field) ? '已上傳' : '未上傳'))]),(_vm.getCustomFormData(field) && _vm.useFeatures.viewRestrictedFile)?_c('BaseElButton',{staticClass:"text-primary-100 underline",attrs:{"type":"text"},on:{"click":function($event){_vm.onViewFile(_vm.getCustomFormData(field))}}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("查看")])]):_vm._e()],1):_vm._e()])}),_c('BaseElFormItem',{attrs:{"label":"建立來源"}},[_c('span',[_vm._v(_vm._s(_vm.memberOrigin(_vm.member.origin, _vm.member.originText)))])]),_c('BaseElFormItem',{attrs:{"label":"建立時間"}},[_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.member.createdAt, 'YYYY/MM/DD HH:mm:ss')))])])],2)],1),(_vm.showEditDialog)?_c('MemberProfileEdit',{attrs:{"member":_vm.formData,"levelList":_vm.levelList,"baseUserSetting":_vm.baseUserSetting,"customUserSetting":_vm.customUserSetting,"opentixBinded":_vm.opentixBinded,"useMemberOpentix":_vm.useMemberOpentix},on:{"refresh":function($event){return _vm.$emit('update')},"close":function($event){_vm.showEditDialog = false}}}):_vm._e(),(_vm.showMemberLevelDetail)?_c('MemberLevelDetail',{attrs:{"memberId":_vm.memberId},on:{"close":function($event){_vm.showMemberLevelDetail = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }