<template>
  <div class="service-category-setting">
    <!-- <PageTitle title="訂單列表" hideBtn /> -->

    <section>
      <BaseTable
        v-loading="loading"
        :data="productCategoryList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn label="訂單日期" prop="code" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          label="姓名"
          prop="Member.UserInfo.name"
          align="center"
        />
        <BaseElTableColumn label="訂單編號" prop="code" align="center" />
        <BaseElTableColumn label="狀態" prop="status" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.status)">
              {{ orderStatus(scope.row.status, scope.row.cancelBy) }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          label="訂單運送方式"
          prop="EcommerceOrderDelivery.name"
          align="center"
        >
          <template slot-scope="scope">
            {{
              scope.row.EcommerceOrderDelivery
                ? scope.row.EcommerceOrderDelivery.name
                : ''
            }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="訂單總金額" prop="totalPrice" align="center" />
        <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              editBtn="檢視"
              hideDelete
              @edit="checkDetail(scope.row.id)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="productCategoryCount"
        @pageChange="refresh"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { GetOrder, GetOrderCount } from '@/api/ecommerce/order'
import {
  orderStatusConfig,
  deliveryTypesConfig,
  cancelByKeysConfig,
} from '@/config/ecommerce'
import dayjs from '@/lib/dayjs'

// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { extractList } from '@/utils/helper'

// import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'ServiceCategorySetting',
  components: { EmptyBlock },
  props: {
    memberId: String,
  },

  data: () => ({
    deliveryTypesConfig,
    loading: false,

    search: {
      code: '',
      delivery: '',
      member: '',
      status: '',
    },

    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    cancelByKeysConfig,
    orderStatusConfig,
    productCategoryList: [],
    productCategoryCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    avatarChanged: false,
    imgSize: 90,

    formData: {
      name: '',
      id: '',
    },
  }),

  computed: {
    ...mapGetters(['shop']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    imageIdList () {
      return extractList('id', this.formData.Images)
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    dateFormat (date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    },
    tagType (val) {
      let type = 'warning'
      if (val === 'completed') type = 'info'
      if (val === 'open') type = 'action'
      if (val === 'picking') type = 'warning'
      if (val === 'shipped') type = 'action'
      if (val === 'requestRefunded') type = 'danger'
      if (val === 'cancel') type = 'info'
      return type
    },

    checkDetail (id) {
      this.$router.push(`/order-detail/${id}`)
    },

    orderStatus (status, cancelBy) {
      let label = ''
      if (cancelBy) {
        if (status === 'cancel') label = this.cancelByKeysConfig[cancelBy].name
        else label = this.orderStatusConfig[status].name
      } else label = this.orderStatusConfig[status].name

      return label
    },

    deliveryTypes (status) {
      return this.deliveryTypesConfig[status]
    },

    async refresh () {
      this.loading = true
      await this.getOrder()
      await this.getOrderCount()
      this.loading = false
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        isPublic: true,
        avatar: null,
        name: '',
        order: 100,
        id: '',
        services: [],
      }
    },

    //= > 取得訂單
    async getOrder () {
      this.loading = true
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, error] = await GetOrder({
        shopId: this.shop,
        start: startIndex,
        limit,
        // code: !this.search.code ? undefined : this.search.code,
        // status: !this.search.status ? undefined : this.search.status,
        MemberId: !this.memberId ? undefined : this.memberId,
        EcommerceDeliveryId: !this.search.delivery
          ? undefined
          : this.search.delivery,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryList = res
    },

    //= > 取得訂單總數
    async getOrderCount () {
      this.loading = true
      const [res, error] = await GetOrderCount({
        shopId: this.shop,
        // code: !this.search.code ? undefined : this.search.code,
        // status: !this.search.status ? undefined : this.search.status,
        MemberId: !this.memberId ? undefined : this.memberId,
        EcommerceDeliveryId: !this.search.delivery
          ? undefined
          : this.search.delivery,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryCount = res.count
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss">
.unpaid,
.picking,
.shipped {
  color: #5e5e5e !important;
  background: #d5f4f6 !important;
}

.completed {
  color: white !important;
  background: #2bb8c1 !important;
}

.open {
  color: #5e5e5e !important;
  background: #dcd4f0 !important;
}

.cancel,
.clientCancel,
.adminCancel,
.systemCancel {
  color: #5e5e5e !important;
  background: #dedede !important;
}

.requestRefunded {
  background: #f9e2eb;
  color: #fc6670;
}

.filter-block-container {
  @apply flex;
}

@media screen and (max-width:1024px) {
  .filter-block-container {
    @apply grid gap-[12px];
  }
}
</style>
