<template>
  <el-dialog
    title="提醒"
    :visible="show"
    width="400px"
    :close-on-click-modal="false"
    @close="$emit('update:show', false)"
  >
    <div v-loading="loading">
      <div>
        解除綁定後，首次重新使用Line登入此帳號者，將會重新綁定到登入使用的Line。
      </div>
      <div class="btn-container">
        <BaseElButton
          style="width: 140px"
          plain
          @click="$emit('update:show', false)"
        >
          取消
        </BaseElButton>
        <BaseElButton
          style="width: 140px"
          type="primary"
          @click="deleteMemberAuthLine"
        >
          確定
        </BaseElButton>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { DeleteMemberAuthLine } from '@/api/member'
export default {
  name: 'DeBindAuthLineDialog',
  props: ['show', 'member'],
  data: () => ({
    loading: false,
  }),
  computed: {
    shop () {
      return this.$store.getters.shop
    },
  },
  methods: {
    async deleteMemberAuthLine () {
      this.loading = true
      const [, err] = await DeleteMemberAuthLine({
        shopId: this.shop,
        id: this.member.id,
      })
      if (err) {
        this.loading = false
        this.$message.error(err)
        return
      }
      this.loading = false
      this.$message.success('解除綁定成功')
      this.$emit('debind')
      // console.log(res)
    },
  },
}
</script>

<style lang="postcss" scoped>
.btn-container {
  @apply mt-[40px] flex justify-between;
}
</style>
