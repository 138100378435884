export const pointCardStatusConfig = {
  available: { label: '可使用', value: 'available', tagType: 'action', amount: 'availableAmount', amountLabel: '' },
  used: { label: '使用完畢', value: 'used', tagType: 'info', amount: 'availableAmount', amountLabel: '' },
  expired: { label: '已過期', value: 'expired', tagType: 'info', amount: 'expiredAmount', amountLabel: '過期' },
  void: { label: '已作廢', value: 'void', tagType: 'info', amount: 'voidAmount', amountLabel: '作廢' },
}

export const pointCardOriginConfig = {
  admin: { label: '後台新增', value: 'admin' },
  memberStore: { label: '會員商城', value: 'memberStore' },
  subscription: { label: '訂閱方案', value: 'subscription' },
  memberBenefit: { label: '會員權益', value: 'memberBenefit' },
}

export const pointCardUseRecordOriginConfig = {
  systemExpire: { label: '系統過期', value: 'systemExpire', symbol: '' },
  adminVoid: { label: '後台作廢', value: 'adminVoid', symbol: '' },
  adminUse: { label: '後台使用', value: 'adminUse', symbol: '-' },
  adminAdd: { label: '後台補發', value: 'adminAdd', symbol: '' },
  entryControlUse: { label: '進場使用', value: 'entryControlUse', symbol: '-' },
  appointmentUse: { label: '預約使用', value: 'appointmentUse', symbol: '-' },
}
