<template>
  <div class="mb-[32px]">
    <BaseElForm
      label-width="150px"
      label-position="top"
      :model="formData"
      :rules="formRules"
      ref="formRef"
    >
      <BaseElFormItem label="使用點數" prop="amount">
        <div class="flex gap-[8px]">
          <BaseElInput v-model="formData.amount" placeholder="請輸入數字">
            <p slot="suffix">點</p>
          </BaseElInput>
          <BaseElButton
            type="primary"
            @click="onSubmit"
          >
            確定
          </BaseElButton>
      </div>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import { useBaseForm } from '@/use/useForm'
import { UsePointCardRecord } from '@/api/pointCard'
import { noEmptyRules } from '@/validation'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'PointCardUseBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit}) {
    const { shopId } = useShop()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    initFormData({
      amount: '',
    })
    const formRules = computed(() => {
      return {
        amount: [noEmptyRules()],
      }
    })
    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) {
        loading.value = false
        return
      }
      usePointCardRecord()
      loading.value = false
    }
    const usePointCardRecord = async () => {
      const [res, err] = await UsePointCardRecord({
        shopId: shopId.value,
        pointCardRecordId: get(props.data, 'id'),
        amount: formData.amount,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('使用成功')
      formData.amount = null
      emit('refresh')
    }
    return {
      get,
      formRef,
      formData,
      formRules,
      initFormData,
      loading,
      checkForm,
      onSubmit,
    }
  }
})
</script>

<style scoped lang="postcss">
::v-deep .el-input {
  @apply w-full !max-w-full;
}
::v-deep .el-input__inner {
  @apply !h-auto;
}
</style>
