<template>
  <div class="coupon-history-tab">
    <PageTitle title="" btn="新增票券" @btnClick="showDialog = true" />

    <FiltersContainer>
      <BaseElSelect v-model="search.status" clearable placeholder="使用狀態" @change="refresh(true)">
        <BaseElSelectOption
          v-for="option in couponStatusConfig"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </BaseElSelect>

      <BaseElSelect v-model="search.orderBy" clearable placeholder="排序" @change="refresh(true)">
        <BaseElSelectOption
          v-for="option in orderByOptionsConfig"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </BaseElSelect>
    </FiltersContainer>

    <BaseTable :data="couponHistoryList">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="來源" align="center">
        <template slot-scope="scope">
          <div>
            {{ transOriginType(scope.row.originType) }}
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="票券編號" prop="code" align="center">
        <template>
          <div slot-scope="scope">
            {{ scope.row.code }}
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="種類" align="center">
        <template slot-scope="scope">
          <div>
            {{ couponType(scope.row.Coupon.type) }}
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="名稱" prop="Coupon.name" align="center" />
      <BaseElTableColumn label="兌換碼" prop="exchangeCode" align="center">
        <template slot-scope="scope">
          <div>
            {{ scope.row.exchangeCode || '-' }}
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="使用狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="tagType(scope.row.status)">
            {{ tagTrans(scope.row.status) }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="發送日期" align="center">
        <template slot-scope="scope">
          <div>
            {{ scope.row.createdAt ? dateFormat(scope.row.createdAt) : '-' }}
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="使用日期" align="center">
        <template slot-scope="scope">
          <div>
            {{ scope.row.usedAt ? dateFormat(scope.row.usedAt) : '-' }}
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="到期日期" prop="expAt" align="center">
        <template slot-scope="scope">
          <div>
            {{ dateFormat(scope.row.expAt) }}
          </div>
        </template>
      </BaseElTableColumn>

      <BaseElTableColumn
        prop="originName"
        label="備註"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.originName || '-' }}
        </template>
      </BaseElTableColumn>

      <BaseElTableColumn label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            editBtn="核銷"
            :editDisabled="disableUseCoupon(scope.row.status)"
            @edit=";(selectCoupon = scope.row), (showUseCouponDialog = true)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="couponHistoryCount"
      @pageChange="refresh"
    />

    <!-- Dialog -->
    <el-dialog
      title="新增票券"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm"
    >
      <BaseElForm
        ref="form"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem label="種類" require>
          <BaseElRadioGroup v-model="formData.type" style="margin: 8px 0">
            <BaseElRadio v-if="useCouponFeature" label="coupon">使用券</BaseElRadio>
            <BaseElRadio v-if="useCouponExchangeFeature" label="exchange">兌換券</BaseElRadio>
            <BaseElRadio v-if="usePospalCouponFeature" label="pospal">瑞乘優惠券</BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>

        <BaseElFormItem
          prop="coupon"
          label="票券"
          :error="couponExpError ? '票券已過期' : ''"
        >
          <CouponSearch
            v-if="useCouponFeature"
            v-show="formData.type === 'coupon'"
            :model.sync="formData.coupon"
          />
          <CouponExchangeSearch
            v-if="useCouponExchangeFeature"
            v-show="formData.type === 'exchange'"
            :model.sync="formData.coupon"
          />
          <PospalCouponSearch
            v-if="formData.type === 'pospal'"
            v-show="formData.type === 'pospal'"
            :value.sync="formData.coupon"
          />
        </BaseElFormItem>

        <BaseElFormItem prop="message" label="搭配訊息">
          <BaseElInput
            v-model="formData.message"
            type="textarea"
            show-word-limit
            :autosize="{ minRows: 5, maxRows: 5 }"
            maxlength="1000"
            placeholder="請輸入"
          />
        </BaseElFormItem>
      </BaseElForm>
      <div slot="footer">
        <BaseElButton
          class="btn"
          plain
          @click=";(showDialog = false), (selectRow = null)"
        >
          返回
        </BaseElButton>
        <BaseElButton class="btn" type="primary" @click="createCouponRecord">
          新增
        </BaseElButton>
      </div>
    </el-dialog>

    <WarningDialog
      v-if="showWarningDialog"
      content="未開啟 Line 訊息通知，確認發送？"
      @close="showWarningDialog = false"
      @confirm="createCouponRecord($event, true)"
    />

    <WarningDialog
      v-if="showUseCouponDialog"
      content="核銷後會員將無法再使用此使用券，確認核銷？"
      @close="showUseCouponDialog = false"
      @confirm="useCoupon"
    />
  </div>
</template>

<script>
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { GetLinePushConfig } from '@/api/shop'
import {
  CreateCouponRecord,
  GetCouponRecord,
  GetCouponRecordCount,
  UseCoupon,
} from '@/api/lottery/couponRecord'
import { noEmptyRules } from '@/validation/index'
import formUtils from '@/utils/form'
import CouponSearch from '@/components/Search/lottery/CouponSearch.vue'
import CouponExchangeSearch from '@/components/Search/lottery/CouponExchangeSearch.vue'
// Mixin
import tableMixin from '@/mixin/table'
import dialogMixin from '@/mixin/dialog'
import dayjs from '@/lib/dayjs'
import { get } from 'lodash'
import { mapGetters } from 'vuex'
import { couponStatusConfig, orderByOptionsConfig } from '@/config/coupon'
import PospalCouponSearch from '@/components/Search/PospalCouponSearch.vue'
import { computed, defineComponent } from 'vue'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'CouponHistoryTab',
  components: { WarningDialog, CouponSearch, CouponExchangeSearch, PospalCouponSearch },
  mixins: [tableMixin, dialogMixin],
  props: ['memberId'],
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const useCouponExchangeFeature = computed(() => checkAction('admin.couponExchange.page'))
    const useCouponFeature = computed(() => checkAction('admin.coupon.page'))
    const usePospalCouponFeature = computed(() => checkAction('admin.couponPospal.find'))

    return { checkAction, useCouponFeature, useCouponExchangeFeature, usePospalCouponFeature }
  },
  data: () => ({
    couponStatusConfig,
    orderByOptionsConfig,
    notifyConfig: {},
    couponHistoryList: [],
    couponHistoryCount: 0,
    showWarningDialog: false,
    showUseCouponDialog: false,
    couponExpError: false,
    search: {
      status: null,
      orderBy: null,
    },
    formData: {
      // couponId: '',
      coupon: null,
      originName: '',
      type: null,
      message: null,
    },
    selectCoupon: null,
    formRules: {
      // couponId: [noEmptyRules()],
      coupon: [noEmptyRules()],
      originName: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters(['userPlanFeature', 'userFeatures']),
    couponNotify () {
      return this.notifyConfig.couponRecordOpen
    },

  },

  async mounted () {
    this.getLinePushConfig()
    await this.refresh()
    if (this.useCouponFeature) {
      this.formData.type = 'coupon'
    } else {
      this.formData.type = 'exchange'
    }
  },
  methods: {
    async handleUseCoupon () {},
    disableUseCoupon (status) {
      return status === 'complete' || status === 'overdue'
    },
    async getLinePushConfig () {
      try {
        const config = await GetLinePushConfig({ shopId: this.shop })
        this.notifyConfig = config
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    checkCouponExp () {
      const expAt = get(this.formData.coupon, 'expAt')
      if (dayjs().isAfter(dayjs(expAt))) this.couponExpError = true
      else this.couponExpError = false
    },
    dateFormat (date) {
      if (!date) return '無'
      return dayjs(date).format('YYYY/MM/DD HH:mm')
    },

    tagType (status) {
      const dict = {
        open: 'action',
        complete: 'warning',
        overdue: 'danger',
      }
      return dict[status]
    },
    tagTrans (status) {
      const dict = {
        open: '未使用',
        complete: '已使用',
        overdue: '已過期',
      }
      return dict[status]
    },
    couponType (type) {
      const dict = {
        coupon: '使用券',
        exchange: '兌換券',
        opentix: 'OT 優惠券',
        pospal: '瑞乘優惠券',
      }
      return dict[type]
    },
    transOriginType (originType) {
      const dict = {
        groupPushMessage: '分群推播',
        admin: '後台新增',
        lottery: '抽獎活動',
        memberStorePointExchange: '點數兌換',
        memberStore: '商城新增',
        eventPlaybook: '系統行銷活動發放',
      }
      return dict[originType]
    },
    resetForm () {
      this.couponExpError = false
      formUtils.resetForm(this.$refs.form)
    },
    async refresh () {
      this.getCouponRecord()
      this.getCouponRecordCount()
    },

    async createCouponRecord (e, force = false) {
      this.checkCouponExp()
      if (this.couponExpError) return
      if (!(await formUtils.checkForm(this.$refs.form))) return
      if (!force && !this.couponNotify) {
        this.showWarningDialog = true
        return
      }
      const [, err] = await CreateCouponRecord({
        shopId: this.shop,
        memberId: this.memberId,
        couponId: get(this.formData.coupon, 'id', undefined),
        // originName: this.formData.originName,
        message: this.formData.message,
      })
      if (err) {
        this.showWarningDialog = false
        return this.$message.error(err)
      }
      this.showDialog = false
      this.showWarningDialog = false
      this.refresh()
    },

    async getCouponRecord () {
      const [res, err] = await GetCouponRecord({
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
        MemberId: this.memberId,
        status: this.search.status || undefined,
        sort: this.search.orderBy || undefined,
      })
      if (err) return this.$message.error(err)
      this.couponHistoryList = res
    },

    async getCouponRecordCount () {
      const [res, err] = await GetCouponRecordCount({
        shopId: this.shop,
        MemberId: this.memberId,
        status: this.search.status || undefined,
        sort: this.search.orderBy || undefined,
      })
      if (err) return this.$message.error(err)
      this.couponHistoryCount = res.count
    },

    async useCoupon () {
      const [, err] = await UseCoupon({
        shopId: this.shop,
        id: get(this.selectCoupon, 'id'),
      })
      if (err) return this.$message.error(err)
      this.showUseCouponDialog = false
      this.refresh()
    },
  },
})
</script>

<style lang="postcss" scoped></style>
