<template>
  <div class="change-log">
    <p class="font-medium mb-[24px]">點數變更紀錄</p>
    <el-steps
      v-if="get(data, 'PointCardUsageLogs.length')"
      direction="vertical"
      :active="get(data, 'PointCardUsageLogs.length')"
    >
      <el-step
        v-for="log in displayData"
        :key="log.id"
      >
        <template slot="description">
          <div class="flex flex-col gap-[8px]" style="padding-bottom: 50px">
            <div class="flex flex-col gap-[8px]">
              <p class="dateTime">{{ log.updatedAt }}</p>
              <div v-if="log.showUser" class="flex items-center gap-[12px]">
                <p class="label">操作人員</p>
                <p class="value">{{ log.userName }}</p>
              </div>
              <div class="flex items-center gap-[12px]">
                <p class="label">變更事件</p>
                <p class="value">{{ log.origin }}</p>
              </div>
              <div class="flex items-center gap-[12px]">
                <p class="label">變更數量</p>
                <p class="value">{{ log.amount }}</p>
              </div>
            </div>
          </div>
        </template>
      </el-step>
    </el-steps>
    <EmptyBlock v-else />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import { formatDate } from '@/utils/date'
import { get, map, orderBy } from 'lodash'
import { pointCardUseRecordOriginConfig } from '@/config/pointCard'

export default defineComponent({
  name: 'PointCardUsageLogBlock',
  props: {
    data: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const displayData = computed(() => {
      const data = get(props.data, 'PointCardUsageLogs', [])
      const sortedData = orderBy(data, ['updatedAt'], ['desc'])
      return map(sortedData, (item) => {
        return {
          updatedAt: formatDate(item.updatedAt),
          userName: get(item, 'User.name'),
          origin: selectOrigin(get(item, 'origin'), 'label'),
          amount: `${selectOrigin(get(item, 'origin'), 'symbol')}${get(item, 'amount')}`,
          showUser: [
            pointCardUseRecordOriginConfig.adminUse.value,
            pointCardUseRecordOriginConfig.adminVoid.value,
            pointCardUseRecordOriginConfig.adminAdd.value
          ].includes(get(item, 'origin'))
        }
      })
    })
    const selectOrigin = (origin, attr) => {
      return get(pointCardUseRecordOriginConfig[origin], attr, '')
    }
    return { displayData, get }
  },
})
</script>

<style lang="postcss" scoped>
.dateTime {
  @apply text-gray-60 text-sub;
}

::v-deep .el-step__description {
  @apply pr-0 top-[-4px] relative;
}

::v-deep .el-step__icon.is-text {
  @apply top-[-4px] h-[15px] w-[15px] ml-[5px] bg-primary-100;
}

::v-deep .el-step__icon-inner {
  @apply hidden;
}

.label {
  @apply text-sub font-medium text-gray-100;
}
.value {
  @apply text-sub text-gray-60;
}
</style>
