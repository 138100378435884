<template>
  <div class="wallet-tab">
    <section v-if="useWalletPermission && useDepositWalletPermission">
      <div v-loading="loading" class="use" style="margin-top: 20px">
        <BaseElForm
          ref="walletRecordForm"
          :model="walletRecordForm"
          :rules="walletRecordFormRules"
          label-width="120px"
          label-position="left"
        >
          <BaseElFormItem label="操作" prop="walletAction">
            <BaseElRadioGroup v-model="walletRecordForm.walletAction">
              <BaseElRadio v-if="useWalletPermission" label="withdraw">使用</BaseElRadio>
              <BaseElRadio v-if="useDepositWalletPermission" label="deposit">儲值</BaseElRadio>
            </BaseElRadioGroup>
          </BaseElFormItem>
          <BaseElFormItem label="金額" prop="coin">
            <BaseElInput v-model="walletRecordForm.coin" />
          </BaseElFormItem>
          <BaseElFormItem label="備註" prop="note">
            <BaseElInput v-model="walletRecordForm.note" />
          </BaseElFormItem>
          <BaseElFormItem>
            <BaseElButton
              plain
              @click="
                ;(walletRecordForm.walletAction = ''),
                  (walletRecordForm.coin = '')
              "
            >
              取消
            </BaseElButton>
            <BaseElButton type="primary" @click="handleWallet">儲存</BaseElButton>
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>

    <!-- <h3 class="table-title">儲值金使用紀錄</h3> -->
    <template v-if="useWalletRecordPermission">
      <SectionTitle title="儲值金使用紀錄" hideBtn />
      <BaseTable
        v-loading="loading"
        :data="walletRecordList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="type" label="形式" align="center">
          <template slot-scope="scope">
            {{ convertNoteCode(scope.row.noteCode) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="amount" label="儲值金" align="center" />
        <BaseElTableColumn prop="note" label="備註" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.note || "-" }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="false" prop="Branch" label="操作門市" align="center">
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || "-" }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="createdAt" label="建立時間" width="120" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="walletRecordTableOptions.page"
        :pageLimit="walletRecordTableOptions.pageLimit"
        :total="walletRecordCount"
        @pageChange="refresh('wallet')"
      />
    </template>
  </div>
</template>

<script>
import { noEmptyRules, isDigitRules } from '@/validation/index'
import {
  GiveDepositWallet,
  UseDepositWallet,
  FindDepositWalletRecord,
  DepositWalletRecordCount,
} from '@/api/member'
import EmptyBlock from '@/components/EmptyBlock.vue'
import formUtils from '@/utils/form'
import MixinFunc from '@/components/MixinFunc.vue'
import { pageStartIndex } from '@/utils/table'
import {
  noteCodeOptions,
} from '@/config/wallet'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  name: 'WalletTab',
  components: { EmptyBlock },
  mixins: [MixinFunc],
  props: ['member'],
  data: () => ({
    loading: false,
    walletRecordCount: 0,
    walletRecordList: [],
    walletRecordTableOptions: {
      page: 1,
      pageLimit: 10,
    },
    walletRecordForm: {
      walletAction: '',
      note: '',
      coin: '',
    },
    walletRecordFormRules: {
      coin: [isDigitRules(), noEmptyRules()],
      walletAction: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters(['userPlanFeature', 'userFeatures']),
    shop () {
      return this.$store.getters.shop
    },

    walletRecordStartIndex () {
      return pageStartIndex(
        this.walletRecordTableOptions.page,
        this.walletRecordTableOptions.pageLimit,
      )
    },

    useWalletPermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.member.adminUseWallet')
    },
    useDepositWalletPermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.member.depositWallet')
    },
    useWalletRecordPermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.member.findWalletRecord')
    },
    useStorePermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.branch.adminUseStore')
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    async refresh () {
      this.loading = true
      await this.findDepositWalletRecord()
      await this.depositWalletRecordCount()
      this.loading = false
    },

    walletCoinTagType (val) {
      let type = 'info'
      if (val === 'from') type = 'primary'
      if (val === 'to') type = 'warning'
      return type
    },

    //= > 給予會員儲值金
    async giveDepositWallet () {
      try {
        await GiveDepositWallet({
          shopId: this.shop,
          id: this.member.id,
          amount: this.walletRecordForm.coin,
          note: this.walletRecordForm.note === '' ? undefined : this.walletRecordForm.note,
        })
        await this.findDepositWalletRecord()
        this.walletRecordForm.coin = ''
        this.walletRecordForm.note = ''
        this.$message.success('已儲值儲值金!')
        this.$emit('updateWallet')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 使用會員儲值金
    async useDepositWallet () {
      try {
        if (!await formUtils.checkForm(this.$refs.walletRecordForm)) return
        await UseDepositWallet({
          shopId: this.shop,
          id: this.member.id,
          amount: this.walletRecordForm.coin,
          note: this.walletRecordForm.note === '' ? undefined : this.walletRecordForm.note,
        })

        await this.findDepositWalletRecord()
        this.walletRecordForm.coin = ''
        this.walletRecordForm.note = ''
        this.$message.success('已使用儲值金!')
        this.$emit('updateWallet')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得會員儲值金
    async findDepositWalletRecord () {
      try {
        const res = await FindDepositWalletRecord({
          shopId: this.shop,
          id: this.member.id,
          start: this.walletRecordStartIndex,
          limit: this.walletRecordTableOptions.pageLimit,
        })
        this.walletRecordList = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得會員儲值金
    async depositWalletRecordCount () {
      try {
        const res = await DepositWalletRecordCount({
          shopId: this.shop,
          id: this.member.id,
        })
        this.walletRecordCount = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    handleWallet: debounce(async function () {
      this.loading = true
      const isValid = await formUtils.checkForm(this.$refs.walletRecordForm)
      if (!isValid) return
      if (this.walletRecordForm.walletAction === 'deposit') {
        await this.giveDepositWallet()
      } else {
        await this.useDepositWallet()
      }
      this.loading = false
    }, 300, { leading: false, trailing: true }),

    convertNoteCode (noteCode) {
      if (noteCodeOptions) {
        const item = noteCodeOptions.find(item => item.value === noteCode)
        if (item) return item.label
      }
      return '-'
      // return noteCodeOptions ? noteCodeOptions.find(item => item.value === noteCode).label : '-'
    },
  },

}
</script>

<style scoped lang="scss"></style>
